<template>
  <div>
    <form></form>
    <div v-show="paymentEntry">
      <v-card>
        <v-toolbar :color="$store.state.primaryColor" dark
          ><span class="text-h5 font-weight-black">{{
            $t("mpesa payment")
          }}</span></v-toolbar
        >
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12 class="text-right mt-3 pa-2">
              <input
                id="inputCash"
                type="text"
                :placeholder="$t('enter customers phone number')"
                @keypress="digits"
                class="text-h4 input-style green lighten-5 text-right font-weight-bold"
                v-model="paymentData.customer.phone_number"
                style="width: 100%; border: 1px solid"
              />

              <!-- <span class="text-h6 font-weight-black">Total amount</span> -->
              <v-flex class="text-right my-2">
                <span class="text-h5 font-weight-black">{{
                  $t("total amount")
                }}</span>
              </v-flex>
              <strong
                class="display-1 grey px-3 py-1 rounded text--darken-2 font-weight-black"
              >
                {{ currencyFormat(grandTotal) }}</strong
              >
            </v-flex>

            <v-flex xs12 sm12 md6 lg6 class="text-right pa-2"> </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.primaryColor"
            class="mt-3 white--text"
            :loading="loading"
            @click="asyncPay()"
            style="width: 60%"
          >
            {{ $t("submit") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="goBack()"
            >{{ $t("close") }}
          </v-btn>
        </v-card-actions>

        <v-dialog v-model="dialog" max-width="400" persistent>
          <v-card dark height="30">
            <v-card-text class="py-2">
              <v-progress-linear
                height="10"
                :v-model="progressValue"
                :buffer-value="progressValue"
                stream
                striped
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-snackbar
          v-model="snackError"
          top
          center
          color="red lighten-5 error--text text--darken-3"
          :timeout="3000"
        >
          <span> {{ $t("unable to save receipt, Try again later") }}. </span>
          <template v-slot:action="{ attrs }">
            <v-btn
              v-bind="attrs"
              icon
              color="red darken-3"
              @click="snackError = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <v-snackbar
          v-model="snackErrorPaymentUnsuccesful"
          top
          center
          color="red lighten-5 error--text text--darken-3"
          :timeout="3000"
        >
          <span
            >{{ $t("transaction unsuccessful. Please Try again later") }}.
          </span>
          <template v-slot:action="{ attrs }">
            <v-btn
              v-bind="attrs"
              icon
              color="red darken-3"
              @click="snackErrorPaymentUnsuccesful = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackErrorNotEnough"
          top
          center
          color="red lighten-5 error--text text--darken-3"
          :timeout="3000"
        >
          <span>{{ $t("amount not enough") }}. </span>
          <template v-slot:action="{ attrs }">
            <v-btn
              v-bind="attrs"
              icon
              color="red darken-3"
              @click="snackErrorNotEnough = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </div>
    <!-- ################ -->
    <!-- extra dialog -->

    <div v-show="receipt">
      <v-card class="grey lighten-3">
        <div class="body" id="print" style="padding: 30px">
          <div class="container">
            <div class="row center">
              <div class="column">
                <span class="bold-text">{{
                  getBusinessName(transaction.businessId)
                }}</span>
              </div>
            </div>
            <hr class="white-hr" />
            <div class="row center">
              <div class="column">
                <span class="">{{ building }}</span>
              </div>
            </div>
            <hr class="white-hr" />
            <div class="row center">
              <div class="column">
                <span class="">{{ floor }}</span>
              </div>
            </div>
            <hr class="white-hr" />
            <div class="row center">
              <div class="column">
                <span class="">{{ street }}</span>
              </div>
            </div>
            <hr class="white-hr" />
            <div class="row center">
              <div class="column">
                <span class="">{{ county + " " + country }}</span>
              </div>
            </div>
            <hr class="white-hr" />
            <div class="row center">
              <div class="column">
                <span class="bold-text">{{ formatDate(new Date()) }}</span>
              </div>
            </div>
            <hr class="white-hr" />
            <div class="row center">
              <div class="column">
                <span class="">{{ formatDateMinuteTime(new Date()) }}</span>
              </div>
            </div>
            <hr class="white-hr" />

            <hr class="black-hr" />
            <hr class="black-hr" />
            <!-- ####Product titles##### -->

            <div class="row left black-background" style="margin-top: 10px">
              <div class="column-quarter">
                <span class="white-text">{{ $t("product name") }}</span>
              </div>
              <div class="column-three-quarter">
                <div class="row">
                  <div class="column-quarter">
                    <span class="white-text">{{ $t("quantity") }}</span>
                  </div>
                  <div class="column-quarter">
                    <span class="white-text">{{ $t("price") }}</span>
                  </div>
                  <div class="column-quarter">
                    <span class="white-text">{{ $t("discount") }}</span>
                  </div>
                  <div class="column-quarter right">
                    <span class="white-text">{{ $t("total") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- ########## -->
            <!-- ######Product list########## -->
            <div
              v-for="index in transaction.products"
              :key="index.id"
              style="margin-top: 10px"
            >
              <div class="row left">
                <div class="column-quarter">
                  <span class="bold-text">{{ index.item.name }}</span>
                </div>
                <div class="column-three-quarter">
                  <div class="row">
                    <div class="column-quarter">
                      <span class="">{{ index.qty }}</span>
                    </div>
                    <div class="column-quarter">
                      <span class="">{{ index.price }}</span>
                    </div>
                    <div class="column-quarter">
                      <span class="">{{ index.item.price.discount }}</span>
                    </div>
                    <div class="column-quarter right">
                      <span class="bold-text">{{ index.total }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="black-hr" />
            </div>
            <!-- ############## -->

            <!-- #####Totals section### -->

            <div class="row left">
              <div class="column-quarter">
                <span class="bold-text">{{ $t("sub total") }}</span>
              </div>
              <div class="column-three-quarter">
                <div class="row right">
                  <div class="column-half">&nbsp;</div>
                  <div class="column-half">
                    <span class="bold-text">{{
                      currencyFormat(cartTotal())
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr class="white-hr" />
            <div class="row left">
              <div class="column-quarter">
                <span class="bold-text">{{ $t("V.A.T") }}</span>
              </div>
              <div class="column-three-quarter">
                <div class="row right">
                  <div class="column-half">&nbsp;</div>
                  <div class="column-half">
                    <span class="bold-text">{{
                      currencyFormat(transaction.vat)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <hr class="white-hr" />
            <div class="row left">
              <div class="column-quarter">
                <span class="bold-text">{{ $t("total") }}</span>
              </div>
              <div class="column-three-quarter">
                <div class="row right">
                  <div class="column-half">&nbsp;</div>
                  <div class="column-half">
                    <span class="bold-text">{{
                      currencyFormat(transaction.totalAmount)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <hr class="black-hr" />
            <!-- ########### -->

            <!-- #####Amount paid########## -->
            <div class="row left">
              <div class="column-quarter">
                <span class="">{{ $t("amount paid") }}</span>
              </div>
              <div class="column-three-quarter">
                <div class="row right">
                  <div class="column-half">&nbsp;</div>
                  <div class="column-half">
                    <span class="bold-text">{{
                      currencyFormat(transaction.amountPayed)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- #####change########## -->
            <hr class="white-hr" />

            <div class="row left">
              <div class="column-quarter">
                <span class="">{{ $t("change") }}</span>
              </div>
              <div class="column-three-quarter">
                <div class="row right">
                  <div class="column-half">&nbsp;</div>
                  <div class="column-half">
                    <span class="bold-text">{{ transaction.change }}</span>
                  </div>
                </div>
              </div>
            </div>

            <hr class="black-hr" />
            <hr class="black-hr" />
            <!-- ########### -->
            <!-- #####Served by###### -->
            <div class="row center">
              <div class="column">
                <span class=""
                  >{{ $t("you were served by") }}
                  {{ transaction.cashier }}</span
                >
              </div>
            </div>

            <hr class="black-hr" />
            <!-- ########### -->
            <!-- #####Served by###### -->
            <div class="row center" style="margin-top: 5px">
              <div class="column">
                <barcode
                  v-bind:value="transaction.receiptBarCode"
                  format="CODE39"
                  height="19"
                  width="1.5"
                  fontSize="12"
                  font="Courier"
                  options="{
                    'Short barcode'
                    }"
                >
                  Show this if the rendering fails.
                </barcode>
              </div>
            </div>

            <hr class="black-hr" />
            <!-- ########### -->
            <!-- #####Served by###### -->
            <div class="row center">
              <div class="column">
                <span class="bold-text">{{
                  $t("thank you for doing business with us")
                }}</span>
              </div>
            </div>
            <hr class="black-hr" />
            <!-- ########### -->
            <!-- #####Served by###### -->
            <div class="row center">
              <div class="column">
                <span class="">{{ $t("by Upsell from MijiniTech Ltd") }}</span>
              </div>
            </div>
            <!-- ###### -->
          </div>
        </div>
        <v-card-actions>
          <v-btn
            :color="$store.state.primaryColor"
            class="mt-3 white--text"
            :loading="loading"
            @click="singleTransactions()"
            style="width: 60%"
          >
            {{ $t("submit") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="goBack()"
            >{{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <!-- ################## -->
    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("receipt saved successfully") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { format } from "date-fns";
import db from "@/plugins/fb";
import { digits, printer } from "@/plugins/functions";
import jwtdecode from "jwt-decode";

// import fb from "firebase/app";

export default {
  props: [
    "focus",
    "cart",
    "amountPayed",
    "grandTotal",
    "selectedShop",
    "accounts",
  ],

  watch: {
    focus() {
      let totalBeforeTax = this.cartTotal();
      this.transaction.vat = Math.ceil(totalBeforeTax * (this.vat.vat / 100));
      //call total function
      this.transaction.totalAmount =
        Math.ceil(totalBeforeTax * (this.vat.vat / 100)) + totalBeforeTax;
    },
  },

  data: () => ({
    access_token: "",
    snackbar: false,
    timeout: 2000,
    loading: false,
    dialog: false,
    progressValue: 10,
    snackSuccess: false,
    snackError: false,
    snackErrorNotEnough: false,
    snackErrorPaymentUnsuccesful: false,
    errorText: false,

    building: "",
    floor: "",
    county: "",
    country: "",
    street: "",

    //toggle between receipt and form to enter amount
    paymentEntry: true,
    receipt: false,

    cartData: {},
    vat: [],
    products: [],
    transactionId: "",
    transaction: {
      sellerId: "",
      amountPayed: "",
      businessId: "",
      cashier: "",
      cashierId: "N/A",
      change: 0,

      client: "N/A",
      dateTime: new Date(),
      notes: "N/A",
      paymentMethod: "Flutterwave",
      products: [],
      receiptBarCode: "",
      receiptNo: "",
      totalAmount: "",
      vat: "",
      vatRate: "",
    },

    rules: {
      email: [
        (v) => !!v || this.$t("e-mail is required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("please enter a valid email"),
      ],
      emptyField: [(v) => !!v || this.$t("please fill out this field")],
    },
    //payload sent to flutterwave
    paymentData: {
      tx_ref: "UPSL" + new Date(),
      amount: 2,
      currency: "KES",
      payment_options: "mpesa,card",
      redirect_url: "",
      meta: {
        counsumer_id: "7898",
        consumer_mac: "kjs9s8ss7dd",
      },
      customer: {
        name: "Kyalos Mango",
        email: "kmarcelus92@gmail.com",
        phone_number: "0712763780",
      },
      customizations: {
        title: "",
        description: "Customization Description",
        logo: "",
      },
      onclose: function () {
        console.log("payment modal is closed");
      },
    },
  }),

  computed: {
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    months() {
      return this.$store.getters.getMonths;
    },
    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },

    currentBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },
    today() {
      return format(new Date(), "yyyy-MM-dd");
    },

    selectedBusinessName() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.businessName;
    },

    selectedBusinessEmail() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.email;
    },

    title() {
      return this.$t("receipt");
    },
  },

  mounted() {
    this.setBusiness();
    this.setProducts();
    this.randomNumber();
    this.getUser();
    this.getProducts();
  },

  methods: {
    digits,
    printer,

    //generate random number for receipt
    randomNumber() {
      this.transaction.receiptNo = Math.floor(Math.random() * 10000000); //multiply to generate random number between 0, 100
      this.transaction.receiptBarCode = Math.floor(
        Math.random() * 1000000000000
      ); //multiply to generate random number between 0, 100
    },
    generateTransactionId() {
      return Math.floor(Math.random() * 1000000000);
    },
    //attach the selected currency to money
    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + Number(val).toFixed(2);
    },

    setBusiness() {
      this.transaction.businessId = this.selectedShop;
    },
    setProducts() {
      this.transaction.products = this.cart;
    },

    getBusinessName(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );

      //set business name and logo on flutterwave
      this.paymentData.customizations.title = bizId.businessName;

      this.paymentData.customizations.logo = bizId.logo;

      this.getBusinessLocation(id);
      return bizId.businessName;
    },

    getBusinessLocation(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );

      this.building = bizId.address.building;
      this.floor = bizId.address.no;
      this.county = bizId.address.county;
      this.country = bizId.address.country;
      this.street = bizId.address.street;
    },

    printPage() {
      this.printer("print", "Receipt");

      //empty cart list

      this.transaction.amountPayed = "";
      this.paymentEntry = true;
      this.receipt = false;
      this.$store.commit("SET_CART", "");
      //##############################review this line of code############################
      // this.$store.state.selectedBusiness = "";
      //clear cart data and totals

      this.$emit("clearCartEmit");

      this.goBack();
    },

    formatDate(val) {
      return format(val, "yyyy-MM-dd");
    },
    formatDateMinuteTime(val) {
      return format(val, "hh:mm aa");
    },
    getProducts() {
      db.collection("products")
        .where("businessID", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            //get only items that are in stock
            this.products.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          //get only items that are in stock
          // this.products = this.products.filter(
          //   (item) => item.quantity.inStock > 0
          // );
        });
    },
    saveData() {
      this.progressValue = 50;
      this.loading = true;
      this.dialog = true;

      //check if we have an account under cash
      var accountIndex = this.accounts.findIndex(
        (item) =>
          item.accountName == "mpesa" ||
          item.accountName == "Mpesa" ||
          item.accountName == "MPESA" ||
          item.accountName == "M-PESA"
      );

      var accountAmount = this.accounts[accountIndex].amount;
      this.accounts[accountIndex].amount =
        parseInt(accountAmount) + this.grandTotal;

      db.collection("accounts")
        .doc(this.accounts[accountIndex].id)
        .update(this.accounts[accountIndex])
        .then(() => {
          this.saveReceipts();
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    },
    saveReceipts() {
      db.collection("receipts")
        .add(this.transaction)
        .then(() => {
          this.dialog = false;
          this.snackSuccess = true;
          this.progressValue = 100;
          this.loading = false;
          this.paymentEntry = false;
          this.receipt = true;
        })
        .then(() => {
          setTimeout(() => this.printPage(), 500);
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    },
    singleTransactions() {
      //process data for the transactions table
      // ################
      var transactionType = "Flutterwave";
      for (var b = 0; b < this.cart.length; b++) {
        var trans = {
          amount: this.cart[b].price,
          discount: this.cart[b].discount,
          name: this.cart[b].item.name,
          productId: this.cart[b].item.id,
          quantity: this.cart[b].qty,
          change: 0,
          sellerId: this.user.accessLevel.username,
          businessId: this.cart[b].item.businessID,
          currency: this.$store.state.currency.symbol,
          transactionId: this.generateTransactionId(),
          transactionType: transactionType,
          dateTime: new Date(),
          vat: this.vat.vat,
        };

        //find index of products
        var index = this.products.findIndex(
          (item) => item.id == this.cart[b].item.id
        );

        this.products[index].quantity.delivered =
          this.products[index].quantity.delivered + trans.quantity;
        this.products[index].quantity.inStock =
          this.products[index].quantity.inStock - trans.quantity;
        this.products[index].quantity.ordered =
          this.products[index].quantity.ordered + trans.quantity;
        this.products[index].quantity.remaining =
          this.products[index].quantity.remaining - trans.quantity;

        this.$store.commit("SET_PRODUCTS_POS", this.products);

        db.collection("products")
          .doc(this.products[index].id)
          .update(this.products[index])
          .then(() => {
            console.log("producst Updated");
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });

        db.collection("transactions")
          .add(trans)
          .then(() => {
            console.log("Updated");
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });
      }
      this.saveData();
      // ################
    },

    viewReceipt() {
      this.paymentEntry = false;
      this.receipt = true;
    },

    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      //populate the sellerID
      this.transaction.sellerId = this.user.accessLevel.username;
      // set cashier name
      this.transaction.cashier =
        this.user.names.first + " " + this.user.names.last;

      // get VAT function
      this.getVat(this.user.address.country);
    },

    getVat(country) {
      db.collection("vat")
        .where("country", "==", country)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.vat = doc.data();
          });
          this.transaction.vatRate = this.vat.vat;
          let totalBeforeTax = this.cartTotal();
          this.transaction.vat = Math.ceil(
            totalBeforeTax * (this.vat.vat / 100)
          );
          //call total function
          this.transaction.totalAmount =
            Math.ceil(totalBeforeTax * (this.vat.vat / 100)) + totalBeforeTax;
        });
    },

    cartTotal() {
      let amount = this.transaction.products.map((item) => item.total);
      return amount.length > 1
        ? amount.reduce((prev, next) => Number(prev) + Number(next))
        : amount[0] != null
        ? amount[0]
        : 0;
      // return Number(subTotal) + Number(subTotal * 0.16);
    },

    makePaymentCallback(response) {
      console.log("Payment callback", response);
    },
    closedPaymentModal() {
      console.log("payment modal is closed");
    },
    //send payload to flutter wave asynchronously
    asyncPay() {
      this.asyncPayWithFlutterwave(this.paymentData).then((response) => {
        console.log("flutterwave response");
        console.log(response);
        //check if payment was successful
        if (response.status == "successful") {
          if (response.amount >= this.grandTotal) {
            //amount paid is always equal to
            this.transaction.amountPayed = this.grandTotal;
            this.viewReceipt();
          } else {
            //show amount not enough snackbar
            this.snackErrorNotEnough = true;
          }
        } else {
          this.snackErrorPaymentUnsuccesful = true;
        }
      });
    },
    goBack() {
      // this.$router.push("/point-of-sale");
      this.paymentEntry = true;
      this.receipt = false;
      this.$emit("mpesaDialog");
    },
  },
};
</script>

<style scoped>
.printOut {
  border: 1px solid #eee;
  min-height: 29.7cm;
  width: 21cm;
  padding: 2cm;
  background: #fff;
}
.receiptContents {
  font-family: "Courier New", monospace;
}
.headingRow {
  font-size: 13px;
  color: #aa9494;
  background-color: black;
}
.captionStyle {
  font-size: 13px;
}

.white-hr {
  border: 1px dashed white;
  background-color: white;
}
.black-hr {
  border-top: 1px dashed #000000;
  height: 1px;
  border-radius: 5px;
}
.black-background {
  background-color: #000000;
}
.white-text {
  color: white;
}

.center {
  text-align-last: center;
}
.left {
  text-align-last: left;
}

.right {
  text-align-last: right;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.body {
  font-family: "Courier New", monospace;
  padding: 20px;
}
.body .container {
  background: white;
  color: grey (126, 126, 126);
  font-size: 12px;
  padding: 40px;
}
.bold-text {
  font-weight: bolder;
}
.column {
  float: left;
  width: 100%;
  padding: 10px;
  margin-top: 2px;
}

.column-half {
  float: left;
  width: 50%;
  padding: 10px;
}

.column-third {
  float: left;
  width: 33.33%;
  padding: 10px;

  margin-top: 4px;
}

.column-two-third {
  float: left;
  width: 66.66%;
  padding: 10px;
}

.column-quarter {
  float: left;
  width: 25%;
  padding: 10px;
  margin-top: 2px;
}

.column-three-quarter {
  float: left;
  width: 75%;
  padding: 10px;
}
</style>
